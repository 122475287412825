const i18n = require('i18next');
const { initReactI18next } = require('react-i18next');
const languages = require('./src/data/languages');
const { getLangKey } = require('./src/utils/getLangKey');
require('prismjs/themes/prism-okaidia.min.css');
require('prismjs/plugins/line-numbers/prism-line-numbers.min.css');

const userLang = navigator && (navigator.languages[0] || navigator.language).slice(0, 2);
let locale = ~languages.langs.indexOf(userLang) ? userLang : languages.defaultLangKey;

const translationEN = require('./src/locales/en/translation.json');
const translationDE = require('./src/locales/de/translation.json');

const homeEN = require('./src/locales/en/home.json');
const homeDE = require('./src/locales/de/home.json');

const companyEN = require('./src/locales/en/company.json');
const companyDE = require('./src/locales/de/company.json');

const servicesEN = require('./src/locales/en/services.json');
const servicesDE = require('./src/locales/de/services.json');

const pathArray = window.location.pathname.split('/');
exports.onClientEntry = () => {
  if (window.location.pathname === '/') {
    window.location.pathname = `/${locale}`;
  } else if (pathArray.length > 1) {
    locale = window.location.pathname.split('/')[1];
  }

  i18n.use(initReactI18next).init({
    lng: getLangKey(),
    resources: {
      en: {
        translation: translationEN,
        home: homeEN,
        company: companyEN,
        services: servicesEN,
      },
      de: {
        translation: translationDE,
        home: homeDE,
        company: companyDE,
        services: servicesDE,
      },
    },
    fallbackLng: 'en',

    interpolation: {
      escapeValue: false,
    },
  });

  i18n.changeLanguage(locale);
};
